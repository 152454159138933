@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Roboto+Condensed:ital,wght@1,300&family=Roboto+Slab:wght@100&display=swap");

@font-face {
  font-family: "better_graderegular";
  src: url("../public/bettergrade-519dv-webfont.woff2") format("woff2"),
    url("../public/bettergrade-519dv-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "varsity";
  src: url("../public/varsity_regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}


body {
  padding: 0;
  margin: 0;
  color: white;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  background-color: #000000;
}
.mono {
  font-family: "Ubuntu Mono", monospace;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
