
.footerContainer {
  max-width: 100%;
  width: 100%;
  height: 60vh;
  color: white;
  position: sticky;
  bottom: 0;
  right: 0;
  z-index: 2000;
  padding: 20px;
  padding-right: 30px;
  font-size: medium;
  backdrop-filter: blur(20px);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: -1;
}
.footerContainer > div {
  justify-content: flex-end;
}
.aboutLink {
  color: black;
  font-size: 1.3rem;
  text-shadow: 0 0px 0px black, 0 0 6px black;
  /*box-shadow: outset 0px 0px 0px rgb(0, 0, 0);*/
  padding: 15px;
  text-decoration: none;
  background-color: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  left: 0;
}

.mailImg {
  width: 180px;
  -webkit-filter: opacity(0.5) drop-shadow(0 0 0 white)
    drop-shadow(2px 2px 50px white) drop-shadow(4px 2px 10px white);
  filter: opacity(0.6) drop-shadow(0 0 0 white) drop-shadow(1px 1px 3px white)
    drop-shadow(10px 0px 10px rgba(255, 255, 255, 0.65));
  z-index: -1;
}
.mailDiv {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35%;
  justify-content: space-between;
}
.mailDiv > div {
  display: flex;
  flex-direction: column;
}
.mailContents {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.itemMail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}
.itemMail > label {
  font-weight: bold;
  text-shadow: 0 0px 10px white, 0 0 2px white;
  margin-bottom: 5px;
  /* font-family: "serif"; */
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-left: 10px solid white;
    /* box-shadow: 0px 0px 10px rgb(255, 255, 255); */
  }
}
.aboutTextFooter {
  color: white;
  text-shadow: 0 0px 10px white, 0 0 6px white;
  /* font-family: "better_graderegular"; */
  font-size: 2.4rem;
  width: 100%;
  font-family: "Special Elite", cursive;
  animation: blink-caret 0.75s step-end infinite;
  padding-left: 20px;

  /* transform: rotate(-20deg); */
}

.itemMail > input {
  outline-color: transparent !important;
  outline-width: 0;
  width: 100%;
  padding: 0px 10px;
  outline: none;
  margin-top: 5px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px rgba(144, 144, 144, 0.769);
  color: white;
  font-weight: normal;
  font-size: medium;
  font-family: "Courier Prime", monospace;
  /* font-family: "EB Garamond", serif !important; */
}
.itemMail > input:focus {
  border-bottom: solid 1px magenta;
}
.itemMail > textarea:focus {
  border-bottom: solid 1px magenta;
}
.itemMail > textarea {
  font-family: "Courier Prime", monospace;
  border: none;
  width: 100%;
  padding: 0px 10px;
  outline: none;
  margin-top: 5px;
  border-radius: 5px;
  background-color: transparent;
  border-bottom: solid 1px rgba(144, 144, 144, 0.769);
  font-size: medium;
  color: white;
  height: 100px;
  overflow: hidden;
  font-weight: normal;
}
.submitMail {
  width: 100px;
  padding: 3px 20px;
  background-color: transparent;
  border: solid 1px rgba(144, 144, 144, 0.769);
  border-radius: 5px;
  color: white;
  font-size: large;
  height: 30px;
  font-family: "Courier Prime", monospace;
  transition: all 0.5 ease-in;
}

.submitMail:hover {
  border: solid 1px white;
  background-color: white;
  color: black;
  box-shadow: inset 5px 0px 10px rgb(0, 0, 0), 0px 10px 10px rgb(0, 0, 0);
  padding-top: 5px;
  padding-left: 25px;
}
.loadingDiv {
  width: 100vw !important;
  height: 100%;
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.loadingDiv > h2 {
  text-align: center;
  width: 100%;
}
