.obj {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 20px 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.objSub {
  padding: 20px;
  border-radius: 1%;
  width: 60%;
  height: 50vh;
  text-align: left;
  font-size: medium;
  background-image: linear-gradient(to bottom right, #4949490a, #5f5f5f09);
}
.mainDiv {
  width: 100vw;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  height: 100vh;
}

.seperate {
  -moz-box-shadow: 0 0 30px magenta;
  -webkit-box-shadow: 0 0 30px magenta;
  box-shadow: 0 0 30px magenta;
  background-color: rgba(243, 183, 243, 0.432);
}

.scrollDiv {
  overflow: auto;
  overflow-x: hidden;
  height: 70vh;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-behavior: smooth;
  padding-right: 20px;
}
.scrollDiv::-webkit-scrollbar {
  width: 5px;
}

.scrollDiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.scrollDiv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0, 0, 0);
}

.lineSvgDiv {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

.lineSvg {
  display: inline-block;
  height: 70vh;
}

.dataDiv {
  position: absolute;
  top: 0;
}

.meterDiv {
  height: 100vh;
  display: block;
  position: -webkit-sticky; /* Safari */
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  left: 10%;
}
.meter {
  height: 70%;
  display: flex;
  flex-direction: row;
  position: -webkit-sticky;
  z-index: 0 !important;
}

.demoDiv {
  height: 65%;
  max-height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 10 !important;
  margin-right: 10px;
}

.demoObj {
  display: flex;
  align-items: center;
  position: relative;
  left: 25px;
  z-index: 5;
  height: 25px;
  background-color: transparent;
  border: none;
  color: white;
  padding: 0;
}

.demoObj > h6:hover {
  opacity: 1;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes forOpacityIncrease {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes forOpacityDecrease {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes forOpacityIncreaseTitle {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes forOpacityDecreaseTitle {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes flickerTimeline {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff,
      0 0 40px rgba(255, 0, 255, 0.7), 0 0 80px rgba(255, 0, 255, 0.666),
      0 0 90px rgba(255, 0, 255, 0.687), 0 0 100px rgba(255, 0, 255, 0.598),
      0 0 150px rgba(255, 0, 255, 0.625);
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

.timelineHead {
  text-align: center;
  width: 100%;
  align-self: center;
  color: white;
  z-index: 10;
  margin-bottom: 20px;
  text-shadow: 0 0px 10px rgba(255, 255, 255, 0.456),
    0 0 6px rgba(255, 255, 255, 0.415);
  font-family: "better_graderegular";
  font-weight: normal;
  /* font-size: 3rem; */
  /* position: absolute;
  top: 20px; */

  animation: flickerTimeline 1.5s infinite alternate;
  z-index: 100;
  color: #ffffff;
  font-size: 5rem;
}

.dot {
  height: 6px;
  width: 6px;
  background-color: rgba(255, 255, 255, 0.411);
  border-radius: 100%;
  margin-left: 20px;
  z-index: 5;
}
.activateDot {
  animation: 500ms ease-out 0s 1 forOpacityIncrease;
}
.hideDot {
  opacity: 0;
  animation: 500ms ease-out 0s 1 forOpacityDecrease;
}

.activeRight {
  animation: 500ms ease-out 0s 1 slideInFromLeft;
}
.activeLeft {
  animation: 500ms ease-out 0s 1 slideInFromRight;
}

.passiveTitle {
  opacity: 0.4;
  animation: 500ms ease-out 0s 1 forOpacityDecreaseTitle;
  font-size: small;
  font-weight: normal !important;
}
.activeTitle {
  font-size: x-large;
  animation: 500ms ease-out 0s 1 forOpacityIncreaseTitle;
}
.passive {
  opacity: 0.2;
}
