.navBar {
  color: white;
  position: absolute;
  top: 10px;
  z-index: 2000;
  height: 75px;
  padding-right: 30px;
  width: 70vw;
  font-size: medium;
  background-color: rgba(76, 76, 76, 0.415);
  backdrop-filter: blur(15px);
  box-shadow: 0 0px 30px rgba(255, 255, 255, 0.476);
  transition: box-shadow 500ms ease;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

.logo {
  position: absolute;
  left: 10px;
  font-size: 2rem;
  transition: transform 400ms ease-out, font-size 500ms ease-out,
    opacity 300ms ease-in-out, filter 500ms ease-out;
  animation: fadeIn 1s ease;
  filter: opacity(0.5) drop-shadow(0 0 0 white) drop-shadow(1px 1px 0px white)
    drop-shadow(2px 2px 10px rgba(255, 255, 255, 0.884));
}

.navBar > a {
  margin-left: 20px;
}
.home,
.about,
.resume {
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.resume {
  text-shadow: 0 0 4px rgb(0, 0, 0), 0rgb (0, 0, 0) 1px rgb(0, 0, 0),
    0 0 19px rgb(0, 0, 0), 0 0 40px rgb(0, 0, 0), 0 0 80px rgb(0, 0, 0),
    0 0 90px rgb(5, 5, 5), 0 0 100px rgb(0, 0, 0), 0 0 150px rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  padding: 5px 20px;
  border-radius: 5px;
}
.home:hover {
  color: yellow !important;
  text-decoration: none;
  text-shadow: 0 30px 70px yellow, 0 0 50px yellow;
}
.about:hover {
  color: cyan !important;
  text-shadow: 0 30px 70px cyan, 0 0 50px cyan;
  text-decoration: none;
}
.resume:hover {
  color: magenta !important;
  text-shadow: 0 30px 70px magenta, 0 0 50px magenta;
  text-decoration: none;
}
