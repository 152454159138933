.aboutContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: white;
  position: absolute;
  top: 0;
  margin: 0px;  
  /*padding : 20vh;*/
  max-height: 100vh;
  padding-top: 30px; 
  font-family: "EB Garamond", serif;
  overflow-y: hidden !important;
}
.aboutContainer > p {
  margin-top: 0 !important;
  text-shadow: 0 0px 10px rgba(19, 19, 19, 0.111), 0 0 6px rgba(8, 8, 8, 0.197);
  text-align: center;
  background-color: white;
  width: "100%";
  height: 100vh;
  overflow-y: scroll;
  padding: 0 200px;
  /* position: absolute;
  top: 0; */
  /* margin-top: 100px; */
}
