@keyframes flickerGallery {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px cyan, 0 0 90px cyan, 0 0 100px cyan, 0 0 150px cyan;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

.headGallery {
  position: absolute;
  top: 30%;
  left: 0;
  transition: all 0.9s ease-out;
  transform: translate(100%, 100%);
  width: 100%;
  text-align: center;
  z-index: 10;
  text-shadow: 0 0px 10px rgba(255, 255, 255, 0.456),
    0 0 6px rgba(255, 255, 255, 0.415);
  font-family: "better_graderegular";
  font-weight: normal;
  animation: flickerGallery 1.5s infinite alternate;
  z-index: 100;
  color: #ffffff;
  font-size: 5rem;
}
