@keyframes moveLogo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200px);
  }
}
.introLogo {
  filter: opacity(1) drop-shadow(0 0 0 rgb(0, 0, 0))
    drop-shadow(1px 1px 0px rgb(0, 0, 0))
    drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.884));
}
.titleSplash {
  color: rgb(0, 0, 0);
  font-family: "Special Elite";
  font-size: 6rem;
  text-shadow: 0 0px 10px rgba(0, 0, 0, 0.251), 0 0 6px rgba(0, 0, 0, 0.285);
  border-right: 0.05em solid rgb(0, 0, 0);
  padding-right: 10px;
  position: absolute;
  align-self: center;
  margin-left: 400px !important;
}
.TransitionLogo {
  animation-delay: 800ms !important;
  animation: moveLogo 1s ease-in-out forwards;
  position: absolute;
  align-self: center;
  translate: all 1s ease-out;
  width: 15%;
}

@-webkit-keyframes mask-play {
  from {
    -webkit-mask-position: 0% 0;
    mask-position: 0% 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes mask-play {
  from {
    -webkit-mask-position: 0% 0;
    mask-position: 0% 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

.fadeOutSplash {
  -webkit-animation: mask-play 1.4s steps(70) forwards;
  animation: mask-play 1.4s steps(70) forwards;
}

.splashContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
  background-color: white;
  z-index: 9000;
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 0px 30px rgb(0, 0, 0);
  -webkit-mask: url(../../../public/images/contourMask.png);
  mask: url(../../../public/images/contourMask.png);
  -webkit-mask-size: 7100% 100%;
  mask-size: 7100% 100%;
  transition: all 800ms ease-in-out;
}
